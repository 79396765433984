import React from "react";
import { SessionDTO } from "../../../../common/dto/session.dto";
import { LateralCard, TreyHeader, renderFrequency, durationTime } from "@ivaoaero/front-components";
import './index.scss';
import { useSessionDuration } from "../../../hooks/useSessionDuration";

type SubATCHeaderType = {
  session: SessionDTO;
  position?: any;
};

export const SubATCHeader: React.FC<SubATCHeaderType> = ({ session, position, }) => {
  const lateralBackground = "rgba(255,255,255, 10%)";
  const mainBackground = "#0D2C99";
  const duration = useSessionDuration(session)

  const leftComponent = (
    <LateralCard
      lateralText="Name"
      text={position ? position.atcCallsign : ""}
      lateralBackground={lateralBackground}
      mainBackground={mainBackground}
    />
  );

  const centerComponent = (
    <LateralCard
      lateralText="Duration"
      text={`${session ? duration : "-:--:--"}`}
      lateralBackground={lateralBackground}
      mainBackground={mainBackground}
    />
  );

  const rightComponent = (
    <LateralCard
      lateralText="Frequency"
      text={
        session.atcSession.frequency
          ? renderFrequency(session.atcSession.frequency)
          : "122.800"
      }
      lateralBackground={lateralBackground}
      mainBackground={mainBackground}
    />
  );

  return (
    <TreyHeader
      leftComponent={leftComponent}
      centerComponent={centerComponent}
      rightComponent={rightComponent}
    />
  );
};
