import { TitledSection } from "@ivaoaero/front-components";
import { FC } from "react";
import { SessionDTO } from "../../../common/dto/session.dto";
import { AtisSection } from "../components/AtisSection";
import { PositionMap } from "../components/PositionMap";
import { SubATCHeader } from "../components/SubATCHeader";
import { usePositionInformation } from "../hooks/usePositionInformation";

type Props = { session: SessionDTO; };

export const AtcPage: FC<Props> = ({ session }) => {
  const positionQuery = usePositionInformation(session.callsign);

  return <TitledSection title={"Station"}>
    <SubATCHeader session={session} position={positionQuery.data} />
    <AtisSection sessionId={session.id} />
    <PositionMap position={positionQuery.data} />
  </TitledSection>;
};
