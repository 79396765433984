import { useQuery } from "react-query";
import Http from "../../common/utils/Http";
import { useAuth } from "../../auth/hooks/useAuth";

type UserSettings = {
  "map_layer": string,
  "name_public": boolean,
  "dark_mode": boolean,
  "tracks_interval": number,
  "sso_logout": boolean,
}

const fetchUserSettings = () => Http<UserSettings>({ url: "/v2/users/me/settings/trSettings", params: { default: true } });

export const useSettings = () => {
  const auth = useAuth();

  const query = useQuery(['user', 'settings'], fetchUserSettings, {
    enabled: auth.isAuthenticated,
    staleTime: 0,
    initialData: {
      "map_layer": "voyager",
      "name_public": false,
      "dark_mode": false,
      "tracks_interval": 1,
      "sso_logout": false,
    }
  })
  return query;
};
