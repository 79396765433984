import { useAuth as __useAuth } from "react-oidc-context";

export const useAuth = () => {
  const auth = __useAuth();

  if (auth.error) {
    console.error(auth.error)
  }

  const signOut = async (ssoLogout: boolean = false) => {
    if (ssoLogout)
      await auth.signoutRedirect()
    else {
      await auth.revokeTokens(["access_token"]);
      await auth.removeUser();
      return window.location.replace(auth.settings.post_logout_redirect_uri ?? "https://ivao.aero")
    }
  }

  return { ...auth, signOut };
}