import { useQueryClient, useMutation } from "react-query";
import { useSettings } from "./useSettings";
import Http from "../../common/utils/Http";

const updateSettingAction = async ({ environment, key, value }: { environment: string, key: string, value: any }) => {
  return Http({
    url: `/v2/users/me/settings/${environment}`,
    method: 'PUT',
    data: { key, value }
  })
}

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  const settings = useSettings();

  const updateSettings = useMutation(updateSettingAction, {
    onMutate: (data) => {
      queryClient.setQueryData(['user', 'settings'], {
        ...settings.data,
        [data.key]: data.value
      })
    },
    onError: () => queryClient.invalidateQueries(['user', 'settings'])
  })

  return updateSettings
}