import { durationTime, LateralCard, TreyHeader } from "@ivaoaero/front-components";
import { useLiveSession } from "../hooks/useLiveSession";

type SubHeaderSectionType = {
  connectionType: string;
  user?: any;
};

export const SubHeaderSection: React.FC<SubHeaderSectionType> = ({ connectionType, user }) => {
  const liveSession = useLiveSession(user?.id ?? 0)

  let userHours = "--:--";
  let userRating = "--";

  if (user) {
    if (connectionType === 'PILOT' || connectionType === 'FOLME') {
      let pilot_hours = user.hours.filter((stats: any) => stats.type === 'pilot')
      if (pilot_hours.length > 0) userHours = durationTime(pilot_hours[0].hours)
      userRating = user.rating.pilotRating.name
    } else if (connectionType === 'OBS') {
      userHours = "NOT AVAILABLE"
      userRating = user.isStaff ? "STAFF" : "OBSERVER"
    } else {
      let atc_hours = user.hours.filter((stats: any) => stats.type === 'atc')
      if (atc_hours.length > 0) userHours = durationTime(atc_hours[0].hours)
      userRating = user.rating.atcRating.name
    }
  }

  const leftComponent = (
    <LateralCard
      text={userRating}
      lateralText="Rating"
      lateralBackground="#070C13"
      mainBackground="#1E2229"
    />
  );

  const centerComponent = (
    <LateralCard
      text={userHours === "NOT AVAILABLE" ? `${userHours}` : `${userHours} Hours`}
      lateralText="Hours"
      lateralBackground="rgba(0, 0, 0, 10%)"
      mainBackground="#D7D7DC"
      textColor="dark"
      lateralColor="dark"
    />
  );

  const rightComponent = liveSession.data ? (
    <LateralCard
      text={liveSession.data.callsign}
      lateralText="Status"
      lateralBackground="#2EC662"
      mainBackground="#8CD68C"
    />
  ) : (
    <LateralCard
      text="OFFLINE"
      lateralText="Status"
      lateralBackground="#E93434"
      mainBackground="#EC5C5C"
    />
  );

  return <TreyHeader leftComponent={leftComponent} centerComponent={centerComponent} rightComponent={rightComponent} />
};
