import { FC } from "react";
import { AuthProvider as InternalAuthProvider, AuthProviderProps } from "react-oidc-context";
import { OPENID_AUTHORITY, OPENID_CLIENT_ID, OPENID_REDIRECT_URI } from "../environment";

const oidcConfig: AuthProviderProps = {
  authority: OPENID_AUTHORITY,
  client_id: OPENID_CLIENT_ID,
  redirect_uri: OPENID_REDIRECT_URI,
  scope: 'profile tracker configuration',
  post_logout_redirect_uri: 'https://ivao.aero',
  checkSessionIntervalInSeconds: 15,
  accessTokenExpiringNotificationTimeInSeconds: 15 * 60, // refresh every 15 min
  revokeTokensOnSignout: true,
}

export const AuthProvider: FC = ({ children }) => {
  return <InternalAuthProvider {...oidcConfig}>{children}</InternalAuthProvider>;
}
