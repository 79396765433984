import React from "react";
import { History } from "history";
import { SessionDTO } from "../../dto/session.dto";
import {
  dateFormat,
  durationTime,
  fullName,
  ListTable,
} from "@ivaoaero/front-components";

type FlightListSectionProps = {
  sessions: SessionDTO[];
  history: History;
  next?: () => void;
  hasMore?: boolean;
  enableClick?: boolean;
};

export const AtcListTable: React.FC<FlightListSectionProps> = ({
  sessions,
  history,
  next,
  hasMore,
  enableClick,
}) => {
  const handleFlightClick = (flight: SessionDTO) => {
    enableClick && history.push(`/sessions/${flight.id}`);
  };

  const columns = [
    {
      name: "Callsign",
      selector: "callsign",
    },
    {
      name: "User",
      selector: "user",
      format: ({ user }: any) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(`https://ivao.aero/Member.aspx?Id=${user.id}`);
          }}
        >
          {fullName(user, true)}
        </a>
      ),
    },
    {
      name: "Start",
      selector: "createdAt",
      // @ts-ignore
      format: (item) => dateFormat(item.createdAt),
    },
    {
      name: "End",
      selector: "completedAt",
      // @ts-ignore
      format: (item) =>
        item.completedAt ? dateFormat(item.completedAt) : "--",
    },
    {
      name: "Duration",
      selector: "time",
      // @ts-ignore
      format: (item) => durationTime(item.time),
    },
  ];

  return (
    <ListTable
      columns={columns}
      items={sessions}
      hasMore={hasMore}
      onClick={handleFlightClick}
      next={next}
      enableClick={enableClick}
    />
  );
};
