import { zeroLeftAdder } from "@ivaoaero/front-components";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React, { useMemo } from "react";
import { FlightTrackPointDTO } from "../../../../common/dto/flight-track-point.dto";
import { useSettings } from "../../../../settings";

type DataChartProps = {
  tracks: FlightTrackPointDTO[];
};

const lightTheme = {
  chart: {
    backgroundColor: {
      linearGradient: [0, 0, 500, 500],
      stops: [
        [0, "#fff"],
        [1, "#fff"],
      ],
    },
  },
  subtitle: {
    style: {
      color: "white",
      font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  legend: {
    itemStyle: {
      font: "9pt Trebuchet MS, Verdana, sans-serif",
      color: "white",
    },
    itemHoverStyle: {
      color: "white",
    },
  },
};
const darkTheme = {
  chart: {
    backgroundColor: {
      linearGradient: [0, 0, 500, 500],
      stops: [
        [0, "#292929"],
        [1, "#292929"],
      ],
    },
  },
  subtitle: {
    style: {
      color: "white",
      font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  legend: {
    itemStyle: {
      font: "9pt Trebuchet MS, Verdana, sans-serif",
      color: "white",
    },
    itemHoverStyle: {
      color: "white",
    },
  },
};

export const TracksChart: React.FC<DataChartProps> = ({ tracks }) => {
  const settings = useSettings();

  const isDarkMode = useMemo(() => settings.data?.dark_mode, [settings]);

  // @ts-ignore
  isDarkMode && Highcharts.setOptions(darkTheme);
  // @ts-ignore
  !isDarkMode && Highcharts.setOptions(lightTheme);

  const options = {
    plotOptions: {
      series: {
        allowPointSelect: true,
      },
    },
    title: {
      text: null,
    },
    chart: {
      zoomType: "x",
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
    series: [
      {
        name: "Altitude",
        data: tracks.map((point) => point.altitude),
        yAxis: 1,
        color: "#0D2C99",
        lineWidth: 5,
        tooltip: {
          valueSuffix: " ft",
        },
      },
      {
        name: "Ground Speed",
        data: tracks.map((point) => point.groundSpeed),
        yAxis: 0,
        color: "#7EA2D6",
        lineWidth: 5,
        tooltip: {
          valueSuffix: " kts",
        },
      },
    ],
    yAxis: [
      {
        labels: {
          format: "{value} kts",
          style: { color: isDarkMode ? "white" : null },
        },
        title: {
          text: "Ground Speed",
          style: { color: isDarkMode ? "white" : null },
        },
        opposite: true,
      },
      {
        gridLineWidth: 0,
        title: {
          text: "Flight Level",
          style: { color: isDarkMode ? "white" : null },
        },
        labels: {
          formatter: (a: any) => {
            return `FL ${zeroLeftAdder(
              Math.trunc(a.value / 100).toString(),
              3
            )}`;
          },
          style: { color: isDarkMode ? "white" : null },
        },
      },
    ],
    xAxis: [
      {
        categories: tracks.map((point) =>
          moment.utc(point.timestamp).format("HH:mm")
        ),
        crosshair: true,
        labels: {
          style: { color: isDarkMode ? "white" : null },
        },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
