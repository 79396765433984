import { dateFormat } from "@ivaoaero/front-components";
import { GreatCircle } from "arc";
import { Overlay } from "ol";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import { defaults as defaultControls } from 'ol/control.js';
import { LineString, Point } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";
import { Vector as VectorSource } from "ol/source";
import { Circle, Fill, Stroke, Style } from "ol/style";
import React, { useEffect, useState } from "react";
import { FlightTrackPointDTO } from "../../../../common/dto/flight-track-point.dto";
import { useMapLayer } from "../../../../settings";

type DataChartProps = { tracks: FlightTrackPointDTO[]; };

export const TracksMap: React.FC<DataChartProps> = ({tracks}) => {
  const mapLayer = useMapLayer();
  const [map] = useState<Map>(new Map({
    view: new View({zoom: 1, center: [-94.9065, 38.9884]}),
    controls: defaultControls({ attribution: true, attributionOptions: {
      collapsed: false,
    }}),
  }));
  const vectorSource = new VectorSource();
  let popupOverlay = new Overlay({});

  const checkPopup = (e: any) => {
    const elements: Feature[] = [];
    map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
      if (feature.get('type') === 'marker') {
        // @ts-ignore
        elements.push(feature);
      }
    });


    if (!elements.length) return popupOverlay.setPosition(undefined);
    // @ts-ignore
    const position: FlightTrackPointDTO = elements[0].get('properties').position;

    // const point: FlightTrackPointDTO = element;
    // console.log(point)
    // @ts-ignore
    document.getElementById('popup').innerHTML = `<p>${dateFormat(position.timestamp)}<p><p>Speed: ${position.groundSpeed} Knots at ${position.altitude} feet</p>`
    popupOverlay.setPosition(e.coordinate);
  }

  useEffect(() => {
    const vector = new VectorLayer({ source: vectorSource })

    map.addLayer(mapLayer)
    map.addLayer(vector)

    return () => {
      map.removeLayer(mapLayer)
      map.removeLayer(vector)
    }
  }, [map, mapLayer])

  useEffect(() => {
    popupOverlay = new Overlay({
      // @ts-ignore
      element: document.getElementById('popup'),
      offset: [0, -12],
      // @ts-ignore
      positioning: 'bottom-center'
    });

    map.addOverlay(popupOverlay);
    map.on('pointermove', checkPopup);
    map.setTarget('map');

    return () => {
      // @ts-ignore
      map.setTarget(null)
    }
  })

  useEffect(() => {
    if (!tracks.length) return

    // Render Line
    const trackLine: Feature[] = [];
    const trackPoints: Feature[] = [];
    let previousPos: FlightTrackPointDTO;

    tracks.forEach(position => {
      const pointCoords = fromLonLat([position.longitude, position.latitude]);
      const pointFeature = new Feature({geometry: new Point(pointCoords), properties: {position}, type: "marker"});
      trackPoints.push(pointFeature);
      pointFeature.setStyle(new Style({
          image: new Circle({
            radius: 4,
            stroke: new Stroke({
              color: "blue",
            }),
            fill: new Fill({
              color: "blue",
            }),
          }),
        })
      )

      if (previousPos == null) {
        previousPos = position;
        map.get('view').setCenter(fromLonLat([position.longitude, position.latitude]))
        map.get('view').setZoom(6)
        return;
      }

      const point1 = previousPos;
      const point2 = position;
      previousPos = position;
      const generatorTrackStep = new GreatCircle({
        x: point1.longitude,
        y: point1.latitude
      }, {
        x: point2.longitude,
        y: point2.latitude
      });

      const line = generatorTrackStep.Arc(2);
      for (let geo in line.geometries) {
        const coords = line.geometries[geo].coords;
        const ls = new LineString(coords);
        ls.transform("EPSG:4326", "EPSG:900913");
        const feature = new Feature({geometry: ls, type: "line"});
        feature.setStyle(new Style({
          stroke: new Stroke({
            width: 2,
            color: "blue",
          }),
        }))
        trackLine.push(feature);
      }
    });

    vectorSource.clear()
    vectorSource.addFeatures(trackLine);
    vectorSource.addFeatures(trackPoints);

  }, [map, mapLayer, tracks])

  return <div className="map" id="map" style={{height: "800px", width: "100w", margin: -20}}>
    <div id="popup" className="ol-popup">
      <div id="popup-content"/>
    </div>
  </div>
};
