import React from "react";
import "./subheadersection.scss";
import { ReactComponent as PilotSVG } from "../../common/img/pilot.svg";
import { ReactComponent as AtcSVG } from "../../common/img/atc.svg";
import { ReactComponent as ObsSVG } from "../../common/img/obs.svg";
import { Link } from "react-router-dom";
import { TreyHeader, BackgroundCard } from "@ivaoaero/front-components";

export const SubHeaderSection: React.FC = () => {
  const leftComponent = <BackgroundCard LinkComponent={Link} link={"/pilots"} text={"Pilot"}
    backgroundImage={<PilotSVG className={"main_subheader_section_logo"}
      style={{ height: "130%" }} />} />;

  const centerComponent = <BackgroundCard LinkComponent={Link} link={"/atcs"} text={"ATC"}
    backgroundImage={<AtcSVG className={"main_subheader_section_logo"}
      style={{ height: "150%", right: -40 }} />} />;

  const rightComponent = <BackgroundCard LinkComponent={Link} link={"/observers"} text={"Observers"}
    backgroundImage={<ObsSVG className={"main_subheader_section_logo"}
      style={{ height: "120%" }} />} />;

  return <TreyHeader leftComponent={leftComponent} centerComponent={centerComponent}
    rightComponent={rightComponent} />
};
