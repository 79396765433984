import { dateFormat, LateralCard } from "@ivaoaero/front-components";
import React, { useMemo } from "react";
import { FlightPlanDTO } from "../../../../common/dto/flightplan.dto";
import { SessionDTO } from "../../../../common/dto/session.dto";
import { useSessionDuration } from "../../../hooks/useSessionDuration";
import { FlightPlan } from "../FlightPlan";
import './index.scss';

type SubPilotHeaderType = {
  session: SessionDTO;
  flightPlan?: FlightPlanDTO;
};

export const SubPilotHeader: React.FC<SubPilotHeaderType> = ({ session, flightPlan }) => {
  const lateralBackground = "rgba(255,255,255, 10%)";
  const mainBackground = "#0D2C99";
  const duration = useSessionDuration(session)

  return <div className="row justify-content-md-between justify-content-center">
    <div className="col-12 col-md-6">
      <div className="row justify-content-between">
        <div className="col-12 col-md-6 pilot_subheader_section pilot_subheader_section_left">
          <LateralCard
            lateralText="Leg"
            text={`${flightPlan && flightPlan.departureId ? flightPlan.departureId : "ZZZZ"}-${flightPlan && flightPlan.arrivalId ? flightPlan.arrivalId : "ZZZZ"}`}
            lateralBackground={lateralBackground}
            mainBackground={mainBackground}
          />
        </div>
        <div className="w-100 pt-3 pt-md-0 d-md-none" />
        <div className="col-12 col-md-6 pilot_subheader_section pilot_subheader_section_right">
          <LateralCard
            lateralText="Duration"
            text={`${session ? duration : "-:--:--"}`}
            lateralBackground={lateralBackground}
            mainBackground={mainBackground}
          />
        </div>
      </div>
      <div className="w-100 pt-3 pt-md-3" />
      <div className="row justify-content-between">
        <div className="col-12 col-md-6 pilot_subheader_section pilot_subheader_section_left">
          <LateralCard
            lateralText="On"
            text={`${session ? dateFormat(session.createdAt) : "--"}`}
            lateralBackground={lateralBackground}
            mainBackground={mainBackground}
          />
        </div>
        <div className="w-100 pt-3 pt-md-0 d-md-none" />
        <div className="col-12 col-md-6 pilot_subheader_section pilot_subheader_section_right">
          <LateralCard
            lateralText="Off"
            text={`${session && session.completedAt ? dateFormat(session.completedAt) : "--"}`}
            lateralBackground={lateralBackground}
            mainBackground={mainBackground}
          />
        </div>
      </div>
    </div>
    <div className="w-100 pt-3 pt-md-0 d-md-none" />
    <div className="col-12 col-md-6 pilot_subheader_fp" style={{ overflowY: "scroll", height: 256, borderRadius: 13 }}>
      {flightPlan && <FlightPlan flightPlan={flightPlan} session={session} />}
    </div>
  </div>
};
