import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { SessionDTO } from "../../common/dto/session.dto";
import Http from "../../common/utils/Http";
import { SessionsFilter } from "../dto/SessionsFilter";

export const fetchSessions = async (params: SessionsFilter & { page: number, perPage: number }) => {
  return Http<{ items: SessionDTO[], page: number, pages: number, totalItems: number }>({ url: '/v2/tracker/sessions', params })
}

export const useSessions = (query: SessionsFilter) => {
  const sessions = useInfiniteQuery(['sessions', query], ({ pageParam }) => {
    return fetchSessions({ ...query, page: pageParam, perPage: 50 })
  }, {
    enabled: Boolean(query.userId || query.callsign),
    getNextPageParam: (lastPage) => lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined
  })

  const data = useMemo(() => sessions.data ? sessions.data.pages.flatMap(data => data.items) : [], [sessions.data]);
  const totalItems = useMemo(() => sessions.data ? sessions.data.pages[0].totalItems : 0, [sessions.data])

  return { ...sessions, data, hasMore: !sessions.isFetched || data.length < totalItems };
}