import { useQuery } from "react-query";
import { FlightPlanDTO } from "../../../common/dto/flightplan.dto";
import Http from "../../../common/utils/Http";

const fetchFlightPlans = (sessionId: number) => {
  return Http<FlightPlanDTO[]>({ url: `/v2/tracker/sessions/${sessionId}/flightPlans` })
};

export const useFlightPlans = (sessionId: number) => {
  return useQuery(['session', sessionId, 'flight_plans'], () => fetchFlightPlans(sessionId))
}
