import { CookiesPolicy, Footer, GeneralLayout, PulseLoader } from "@ivaoaero/front-components";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useAuth, useUser } from "./auth";
import { Centered } from "./common/components/Centered";
import { Menu } from "./common/components/menu/Menu";
import { HomePage } from "./home/views/HomePage";
import "./index.scss";
import { SessionPage } from "./session";
import { SessionsPage } from "./sessions";
import { SettingsPage, useCheckDarkMode } from "./settings";
import LogoutPage from "./home/views/LogoutPage";

export default function App() {
  const auth = useAuth();
  const history = useHistory();
  const user = useUser();
  const location = useLocation();
  useCheckDarkMode();

  if (auth.isLoading || user.isLoading) {
    return <Centered><PulseLoader /></Centered>
  }

  if (!auth.isAuthenticated && location.pathname !== '/logout') {
    let redirectUrl = window.location.href.substring(window.location.origin.length);
    if (redirectUrl === '/logout' || redirectUrl === 'logout') redirectUrl = '/'
    window.sessionStorage.setItem('redirect', redirectUrl)
    auth.signinRedirect();
    return <Centered><PulseLoader /></Centered>
  }

  const redirect = window.sessionStorage.getItem('redirect')
  if (redirect) {
    window.sessionStorage.removeItem('redirect')
    history.push(redirect)
    return <Centered><PulseLoader /></Centered>
  }

  const sessionsLists = [
    ["PILOT", "/pilots"],
    ["ATC", "/atcs"],
    ["OBS", "/observers"],
    ["FOLME", "/followMe"],
  ];

  return <div style={{ minWidth: '620px' }}>
    <CookiesPolicy gtm="G-ECRM1YWYF5" />
    <Menu />
    <GeneralLayout>
      <Switch>
        <Route path="/settings" exact component={SettingsPage} />
        <Route path="/logout" exact component={LogoutPage} />
        <Route path="/sessions/:id" exact component={SessionPage} />
        {sessionsLists.map(([connectionType, url]) => <Route key={connectionType} path={url} exact render={(props => <SessionsPage connectionType={connectionType} {...props} />)} />)}
        <Route path="*" exact component={HomePage} />
      </Switch>
    </GeneralLayout>
    <Footer />
  </div>
}
