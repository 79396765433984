import { useSettings } from "../../settings";
import { mapsLayers } from "../../common/utils/MapsLayers";

export const mapsMigration = {
  Basic: 'basic',
  Bright: 'osm-bright',
  'Dark Matter': 'dark-matter',
  Hybrid: 'satellite-hybrid',
  International: 'openstreetmap',
  Liberty: 'osm-liberty',
  Positron: 'positron',
  Toner: 'toner',
  Voyager: 'voyager',
}

export const useMapLayer = (defaultValue: string = "voyager") => {
  const settings = useSettings();
  // @ts-ignore
  const layerFunction = mapsLayers[settings.data?.map_layer ?? defaultValue] ?? mapsLayers[mapsMigration[settings.data?.map_layer ?? "Voyager"]] ?? mapsLayers[defaultValue];
  return layerFunction();
}