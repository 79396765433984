import { TitledSection } from "@ivaoaero/front-components";
import { FC } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { SearchBarSection } from "../../common/components/search-form/SearchBarSection";
import { AtcListTable } from "../../common/components/table/AtcListTable";
import { PilotListTable } from "../../common/components/table/PilotListTable";
import { useSessions } from "../hooks/useSessions";
import { useSessionsFilter } from "../hooks/useSessionsFilter";
import { useUser } from "../hooks/useUser";
import { SessionsPageSlider } from "./SessionsPageSlider";
import { SubHeaderSection } from "./SubHeaderSection";

type Props = RouteComponentProps & { connectionType: string; };

export const SessionsPage: FC<Props> = ({ connectionType }) => {
  const history = useHistory();
  const [filters, setFilters] = useSessionsFilter();
  const user = useUser(filters.userId ?? 0);
  const sessions = useSessions({ connectionType, ...filters } as any);

  // Build Page parameters
  let TableComponent;
  let subtitle;

  const props = {
    sessions: sessions.data,
    history: history,
    hasMore: sessions.hasMore,
    next: () => sessions.fetchNextPage()
  }

  switch (connectionType) {
    case "PILOT":
      TableComponent = <PilotListTable {...props} enableClick={true} />;
      subtitle = "Pilot";
      break;
    case "FOLME":
      TableComponent = <PilotListTable {...props} enableClick={false} />;
      subtitle = "Follow me";
      break;
    case "ATC":
      TableComponent = <AtcListTable {...props} enableClick={true} />;
      subtitle = "ATC";
      break;
    case "OBS":
      TableComponent = <AtcListTable {...props} enableClick={false} />;
      subtitle = "Observer";
      break;
  }

  return (
    <>
      <SessionsPageSlider connectionType={connectionType as any} filters={filters} />
      <TitledSection>
        <SubHeaderSection connectionType={connectionType} user={filters.userId && user.data} />
      </TitledSection>
      <TitledSection title={`Search for ${subtitle}`}>
        <SearchBarSection onSubmit={filters => setFilters(filters)} disabled={{ role: true }} defaultValues={filters} />
      </TitledSection>
      <TitledSection title={subtitle}>
        {TableComponent}
      </TitledSection>
    </>
  );
};
