import { useMemo } from "react"
import { SessionDTO } from "../../common/dto/session.dto"

export const useSessionDuration = ({ time }: SessionDTO) => {
  return useMemo(() => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = (time - (hours * 3600) - (minutes * 60))

    const pad = (time: number) => String(time).padStart(2, "0")

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
  }, [time])
}