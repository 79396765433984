import { fullName, Slider } from "@ivaoaero/front-components";
import { useMemo, VFC } from "react";
import SliderPicture from "../../common/img/slider.png";
import { useUser } from "../hooks/useUser";

type Props = {
  filters: { userId?: number, callsign?: string }
  connectionType: "PILOT" | "FOLME" | "ATC" | "OBS"
}

export const SessionsPageSlider: VFC<Props> = ({ connectionType, filters }) => {
  const user = useUser(filters.userId ?? 0)

  const sliderSubtitle = useMemo(() => {
    if (!filters.userId && !filters.callsign) {
      return "Missing Filters"
    }
    return (user.data ? fullName(user.data) : filters.callsign)
  }, [filters, user.data])

  const pageTitle = { PILOT: "Pilot Data", FOLME: "Follow Me Data", ATC: "ATC Data", OBS: "Observer Data" }[connectionType]

  return <Slider title={pageTitle} subtitle={sliderSubtitle} backgroundImage={SliderPicture} />
}
