import { Feature } from "ol";
import { Circle, Polygon } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import Map from "ol/Map";
import { fromLonLat } from "ol/proj";
import { Vector as VectorSource } from "ol/source";
import { Fill, Stroke, Style } from "ol/style";
import View from "ol/View";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from 'react-bootstrap';
import { useMapLayer } from "../../../../settings";
import "./index.scss";

type PositionMapType = {
  position: any;
};

export const PositionMap: React.FC<PositionMapType> = ({ position }) => {
  const mapLayer = useMapLayer()
  const [map] = useState<Map>(new Map({ view: new View({ zoom: 1, center: [-94.9065, 38.9884] }) }));
  const [feature, setFeature] = useState<Feature>();
  const vectorSource = new VectorSource();
  const vectorLayer = new VectorLayer({ source: vectorSource });
  const layers = [mapLayer, vectorLayer];

  useEffect(() => {
    map.setTarget('map');
    feature && vectorSource.addFeature(feature);
    layers.forEach((layer) => map.addLayer(layer));
    feature && map.getView().fit(vectorSource.getExtent(), { size: map.getSize() });

    return () => {
      // @ts-ignore
      map.setTarget(null)
    }
  }, [feature])

  useEffect(() => {
    if (!position) return;

    // Draw required vars
    let colorStroke = null;
    let colorFill = null;
    let geometry = null;
    let transformCords = false;

    // Switch vars
    let airport = null;
    let coords = null;
    let cordsHuman = null;

    switch (position.position) {
      case 'DEL':
        airport = position.airport;
        coords = fromLonLat([airport.longitude, airport.latitude]);
        colorStroke = "rgb(25, 25, 25)";
        colorFill = "rgba(255, 163, 102, 0.5)";
        geometry = new Polygon([[
          [coords[0] - 9800, coords[1]],
          [coords[0] - 28250, coords[1] + 28250],
          [coords[0], coords[1] + 9800],
          [coords[0] + 28250, coords[1] + 28250],
          [coords[0] + 9800, coords[1]],
          [coords[0] + 28250, coords[1] - 28250],
          [coords[0], coords[1] - 9800],
          [coords[0] - 28250, coords[1] - 28250],
        ]]);
        break;
      case 'GND':
        airport = position.airport;
        coords = fromLonLat([airport.longitude, airport.latitude]);
        colorStroke = "rgb(26, 26, 26)";
        colorFill = "rgba(255, 255, 26, 0.5)";
        geometry = new Polygon([[
          [coords[0] - 7000, coords[1] - 7000],
          [coords[0], coords[1] - 40000],
          [coords[0] + 7000, coords[1] - 7000],
          [coords[0] + 40000, coords[1]],
          [coords[0] + 7000, coords[1] + 7000],
          [coords[0], coords[1] + 40000],
          [coords[0] - 7000, coords[1] + 7000],
          [coords[0] - 40000, coords[1]]
        ]]);
        break;
      case 'TWR':
        colorStroke = "rgb(255, 87, 81)";
        colorFill = "rgba(255, 87, 81, 0.5)";

        if (position.regionMapPolygon.length) {
          geometry = new Polygon([position.regionMapPolygon]);
          transformCords = true;
        } else {
          airport = position.airport;
          coords = fromLonLat([airport.longitude, airport.latitude]);
          geometry = new Circle(coords, 40000);
        }

        break;
      case 'DEP':
        colorStroke = "rgb(255, 204, 255)";
        colorFill = "rgba(255, 204, 255, 0.5)";

        if (position.regionMapPolygon.length) {
          geometry = new Polygon([position.regionMapPolygon]);
          transformCords = true;
        } else {
          airport = position.airport;
          coords = fromLonLat([airport.longitude, airport.latitude]);
          geometry = new Circle(coords, 50000);
        }
        break;
      case 'APP':
        colorStroke = position.military ? "rgb(0, 126, 64)" : "rgb(112, 165, 236)";
        colorFill = position.military ? "rgba(0, 126, 64, 0.5)" : "rgba(112, 165, 236, 0.5)";

        if (position.regionMapPolygon.length) {
          geometry = new Polygon([position.regionMapPolygon]);
          transformCords = true;
        } else {
          airport = position.airport;
          coords = fromLonLat([airport.longitude, airport.latitude]);
          geometry = new Circle(coords, 60000);
        }
        break;
      case 'CTR':
      case 'FSS':
        let crossingDateLine = false;
        transformCords = true;
        cordsHuman = position.regionMapPolygon;

        for (let i = 1; i < cordsHuman.length; i++) {
          if (Math.abs(cordsHuman[i][0] - cordsHuman[i - 1][0]) > 180) {
            crossingDateLine = true;
            break;
          }
        }

        // @ts-ignore
        cordsHuman = cordsHuman.map((i) => {
          i[0] = i[0] < 0 && crossingDateLine ? i[0] += 360 : i[0];
          return [i[0], i[1]];
        })

        colorStroke = position.military ? "rgb(0,126,64)" : "rgb(123, 154, 175)";
        colorFill = position.military ? "rgba(0, 126, 64, 0.5)" : "rgba(123, 154, 175, 0.5)";
        geometry = new Polygon([cordsHuman]);
        break;
      default:
        return;
    }

    const feature = new Feature({
      geometry: geometry,
      type: 'atc',
    });

    // @ts-ignore
    transformCords && feature.getGeometry().transform("EPSG:4326", "EPSG:900913");
    feature.setStyle(new Style({
      stroke: new Stroke({
        color: colorStroke
      }),
      fill: new Fill({
        color: colorFill,
      })
    }));

    setFeature(feature);
  }, [position])

  return <div className="position_map">
    <Accordion defaultActiveKey="0" className="position_map_accordion">
      <Card
        className={`position_map_card`}>
        <Card.Header className="position_map_card_header">
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Station Map
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div className="map" id="map" style={{ height: "800px", width: "100w", margin: -20 }} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>
};
