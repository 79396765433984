import { TitledSection } from "@ivaoaero/front-components";
import React, { VFC } from "react";
import { SessionDTO } from "../../../../common/dto/session.dto";
import { useFlightPlans } from "../../hooks/useFlightPlans";
import { useLastFlightPlan } from "../../hooks/useLastFlightPlan";
import { useTracks } from "../../hooks/useTracks";
import { FlightPlanSection } from "../../components/FlightPlanSection";
import { SubPilotHeader } from "../../components/SubPilotHeader";
import { TrackSection } from "../../components/TrackSection";

type Props = { session: SessionDTO };

export const PilotPage: VFC<Props> = ({ session }) => {
  const flightPlans = useFlightPlans(session.id);
  const lastFlightPlan = useLastFlightPlan(session.id);
  const tracks = useTracks(session.id);

  return (
    <>
      <TitledSection title={"Flight Plans"}>
        <SubPilotHeader session={session} flightPlan={lastFlightPlan!} />
        <FlightPlanSection flightPlans={flightPlans.data ?? []} session={session} loading={flightPlans.isLoading} />
      </TitledSection>
      <TitledSection title={"Tracks"}>
        <TrackSection tracks={tracks.data ?? []} />
      </TitledSection>
    </>
  );
};
