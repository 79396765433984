import { durationTime } from "@ivaoaero/front-components";
import "ol/ol.css";
import { VFC } from "react";
import { FlightPlanDTO } from "../../../../common/dto/flightplan.dto";
import { SessionDTO } from "../../../../common/dto/session.dto";

type Props = {
  flightPlan: FlightPlanDTO;
  session: SessionDTO;
};

export const FlightPlan: VFC<Props> = ({ flightPlan, session }) => {
  return <p className="mb-0" style={{ whiteSpace: "pre-line", paddingLeft: 15 }}>
    <span style={{ marginLeft: 0 }}>(FPL-{session.callsign}-{flightPlan.flightRules}{flightPlan.flightType}</span><br />
    -{flightPlan.aircraftNumber}/{flightPlan.aircraftId}/{flightPlan.aircraft ? flightPlan.aircraft.wakeTurbulence : "ZZZZ"}-{flightPlan.aircraftEquipments.map((a: any) => a.id)}/{flightPlan.aircraftTransponderTypes.map((a: any) => a.id)}<br />
    -{flightPlan.departureId}{durationTime(flightPlan.departureTime).replace(':', '')}<br />
    -{flightPlan.speed}{flightPlan.level} {flightPlan.route}<br />
    -{flightPlan.arrivalId}{durationTime(flightPlan.eet).replace(':', '')} {flightPlan.alternativeId}<br />
    -{flightPlan.remarks})
  </p>;
};
