import { useEffect } from "react";
import { useSettings } from "./useSettings";

export const useCheckDarkMode = () => {
  const darkTheme = useSettings();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isUsingDarkMode = () => {
    if (darkTheme.status !== 'success') {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    }
    return darkTheme.data?.dark_mode;
  }

  useEffect(() => {
    if (isUsingDarkMode()) {
      document.body.setAttribute('data-theme', 'dark')
    } else {
      document.body.removeAttribute('data-theme')
    }
  }, [isUsingDarkMode])
}
