import { fullName, Slider, TitledSection } from "@ivaoaero/front-components";
import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { SearchBarSection } from "../../../common/components/search-form/SearchBarSection";
import { AtcListTable } from "../../../common/components/table/AtcListTable";
import { PilotListTable } from "../../../common/components/table/PilotListTable";
import SliderPicture from "../../../common/img/slider.png";
import { useSessionsFilter, useUser } from "../../../sessions";
import { useHomeSessions } from "../../hooks/useHomeSessions";
import { SubHeaderSection } from "../../sections/SubHeaderSection";
import "./index.scss";

export const HomePage: React.FC<RouteComponentProps> = ({ history }) => {
  const [filters, setFilters] = useSessionsFilter();
  const user = useUser(filters.userId ?? 0);
  const pilotsQuery = useHomeSessions('PILOT', filters)
  const atcsQuery = useHomeSessions('ATC', filters)
  const observerQuery = useHomeSessions('OBS', filters)
  const followMeQuery = useHomeSessions('FOLME', filters)

  // @ts-ignore
  const buildLink = (link: string, f) => `${link}?${new URLSearchParams(f || {}).toString()}`;
  const badgeStyle = { cursor: "pointer" };

  // @ts-ignore
  const ShowMoreComponent = ({ baseLink, filters }) => {
    const link = buildLink(baseLink, filters);

    return (
      <div className="badge badge-success-degraded text-uppercase"
        style={badgeStyle} onClick={() => history.push(link)}>
        <span style={{ padding: 5, fontWeight: "normal" }}>Show More</span>
        <span style={{ paddingTop: 7, paddingBottom: 7, paddingRight: 10, paddingLeft: 10, }} className="badge badge-success">
          <span style={{ fontSize: "20px", marginTop: -7, marginBottom: -7 }}>+</span>
        </span>
      </div>
    );
  };

  const sliderSubtitle = useMemo(() => {
    if (!filters.userId && !filters.callsign) {
      return "Missing Filters"
    }
    return (user.data ? fullName(user.data) : filters.callsign)
  }, [filters, user.data])

  return (
    <>
      <Slider title={"IVAO TRACKER"} subtitle={sliderSubtitle} backgroundImage={SliderPicture} />

      <TitledSection>
        <SubHeaderSection />
      </TitledSection>

      <TitledSection title="Search for a connection">
        <SearchBarSection onSubmit={(f) => {
          setFilters(f);
        }
        } defaultValues={filters} />
      </TitledSection>

      <TitledSection title="Pilot" button={<ShowMoreComponent baseLink="/pilots" filters={filters} />}>
        <PilotListTable sessions={pilotsQuery.data ?? []} history={history} hasMore={pilotsQuery.isLoading} enableClick={true} />
      </TitledSection>

      <TitledSection title="ATC" button={<ShowMoreComponent baseLink="/atcs" filters={filters} />}>
        <AtcListTable sessions={atcsQuery.data ?? []} history={history} hasMore={atcsQuery.isLoading} enableClick={true} />
      </TitledSection>

      <TitledSection title="Observer" button={<ShowMoreComponent baseLink="/observers" filters={filters} />}>
        <AtcListTable sessions={observerQuery.data ?? []} history={history} hasMore={observerQuery.isLoading} enableClick={false} />
      </TitledSection>

      <TitledSection title="Follow Me" button={<ShowMoreComponent baseLink="/followMe" filters={filters} />}>
        <AtcListTable sessions={followMeQuery.data ?? []} history={history} hasMore={followMeQuery.isLoading} enableClick={false} />
      </TitledSection>
    </>
  );
};
