import TileLayer from "ol/layer/Tile";
import { TileJSON } from "ol/source";
import maps from "./maps.json";

const attributionElement = `<a href="https://maptiler.com/?utm_medium=referral&utm_source=ivao&utm_campaign=2023-04%20|%20ivao&utm_term=flight-tracker">
<img src="https://api.maptiler.com/resources/logo.svg" alt="MapTiler logo">
<a/>© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>`;

export const mapsLayers: Record<string, () => TileLayer> = maps.reduce((acc, map) => {
  return {
    ...acc,
    [map.id]: () => new TileLayer({
      // @ts-ignore
      title: map.name,
      source: new TileJSON({
        url: `https://maps-maptiler.ivao.aero/api/maps/${map.id}/tiles.json`,
        wrapX: true,
        attributions: attributionElement,
        tileSize: [512, 512],
      }),
    })
  };
}, {});