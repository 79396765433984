import { dateFormat } from "@ivaoaero/front-components";
import { VFC } from "react";
import { Accordion, Button, Card } from 'react-bootstrap';
import { useAtis } from "../../hooks/useAtis";
import "./index.scss";

type Props = { sessionId: number };

export const AtisSection: VFC<Props> = ({ sessionId }) => {
  const atis = useAtis(sessionId);

  if (atis.isLoading || atis.isError || atis.data?.length === 0) {
    return <></>
  }

  return <div className="atis">
    <Accordion defaultActiveKey="0" className="atis_accordion">
      {atis.data!.map((elem, index) => {
        return <Card key={index}
          className={`atis_card ${index === 0 ? "atis_card_first" : ""} ${index === atis.data!.length - 1 ? "atis_card_last" : ""}`}>
          <Card.Header className="atis_card_header">
            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}>
              <div className="row">
                <div className="col-6 text-left">
                  ATIS {elem.revision}
                </div>
                <div className="col-6 text-right">
                  {dateFormat(elem.timestamp)}
                </div>
              </div>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={`${index}`}>
            <Card.Body>
              {/*@ts-ignore*/}
              {elem.lines.map((line, index) => <p key={index}>{line}</p>)}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      })}
    </Accordion>
  </div>;
};
