import { fullName, Menu as BaseMenu } from "@ivaoaero/front-components";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth, useUser } from "../../../auth";

export const Menu: React.FC = (props) => {
  const auth = useAuth();
  const { user } = useUser();

  const connectionsSubMenu = [
    { name: 'Pilot', link: '/pilots' },
    { name: 'ATC', link: '/atcs' },
    { name: 'Observers', link: '/observers' },
    { name: 'Follow Me', link: '/followMe' }
  ]

  const userSubMenu = [
    { name: 'ivao.aero', link: 'https://ivao.aero', isExternal: true },
    { type: 'divider' },
    { name: 'Settings', link: '/settings' },
    { name: 'Logout', link: '/logout' },
  ];

  const menuItems = [
    { name: 'Home', link: '/' },
    { name: 'Connections', type: 'dropdown', dropdown: connectionsSubMenu },
    { name: user ? fullName(user) : "", type: 'dropdown', dropdown: userSubMenu }
  ]

  return <BaseMenu items={menuItems} {...props} Link={Link} />;
}
