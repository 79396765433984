import { PulseLoader } from "@ivaoaero/front-components";
import { useEffect } from "react";
import { useAuth } from "../../../auth";
import { useSettings } from "../../../settings";
import { Centered } from "../../../common/components/Centered";

const LogoutPage = () => {
  const auth = useAuth();
  const settings = useSettings();

  useEffect(() => {
    auth.signOut(settings.data?.sso_logout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Centered>
      <PulseLoader />
    </Centered>
  );
};

export default LogoutPage;
