import { useQuery } from "react-query"
import { SessionDTO } from "../../common/dto/session.dto"
import Http from "../../common/utils/Http"

const fetchSession = (sessionId: number) => Http<SessionDTO>({
  url: `/v2/tracker/sessions/${sessionId}`
})

export const useSession = (sessionId: number) => {
  return useQuery(['session', sessionId], () => fetchSession(sessionId), { useErrorBoundary: true })
}