
import { useEffect, useState } from "react";
import { useUser as useAuthenticatedUser } from "../../auth";
import { SearchStateObject } from "../../common/components/search-form/SearchBarSection";

export const useSessionsFilter = () => {
  const authenticatedUser = useAuthenticatedUser();
  const [filters, setFilters] = useState<SearchStateObject>(() => {
    const params = new URLSearchParams(window.location.search)
    return {
      // Set default filter to VID if no other filters are set
      userId: (params.get('userId') as any) ?? (!params.get('callsign') && !params.get('from') && !params.get('to') ? authenticatedUser.data?.id : ""),
      callsign: params.get('callsign') ?? "",
      from: params.get('from') ?? "",
      to: params.get('to') ?? "",
    }
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    (["userId", "callsign", "from", "to"] as (keyof SearchStateObject)[]).forEach(key => {
      url.searchParams.delete(key)
      if (filters[key])
        url.searchParams.set(key, String(filters[key]))
    })
    window.history.pushState({}, '', url.toString())
  }, [filters])

  return [filters, setFilters] as const;
}