import React from "react";
import { FlightTrackPointDTO } from "../../../../common/dto/flight-track-point.dto";
import { ListTable, dateFormat } from "@ivaoaero/front-components";

type DataChartProps = {
  tracks: FlightTrackPointDTO[];
};

export const TracksTable: React.FC<DataChartProps> = ({ tracks }) => {
  const columns = [{
    name: "Track time",
    selector: "timestamp",
    format: (item: FlightTrackPointDTO) => dateFormat(item.timestamp)
  }, {
    name: "Latitude",
    selector: 'latitude'
  }, {
    name: "Longitude",
    selector: 'longitude'
  }, {
    name: "Altitude",
    selector: 'altitude',
    format: (item: FlightTrackPointDTO) => `${item.altitude} ft`
  }, {
    name: "Ground Speed",
    selector: 'groundSpeed',
    format: (item: FlightTrackPointDTO) => `${item.groundSpeed} knots`
  }]

  return <div style={{ margin: -22, maxHeight: 500, overflowY: "scroll", overflowX: "scroll" }}>
    <ListTable columns={columns} items={tracks || []} hasMore={false} />
  </div>;
};
