import { fullName, PulseLoader, Slider } from "@ivaoaero/front-components";
import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { AtcPage } from "../../atc-session";
import SliderPicture from "../../../common/img/slider.png";
import { PilotPage } from "../../pilot-session";
import { useSession } from "../../hooks/useSession";

type TParams = { id: string };

export const SessionPage: React.FC<RouteComponentProps<TParams>> = (props) => {
  const sessionQuery = useSession(Number(props.match.params.id))
  let Component, title;

  if (sessionQuery.isLoading) {
    return <div className={'container'}>
      <div style={{ padding: "25%", textAlign: "center" }}>
        <PulseLoader />
      </div>
    </div>
  }

  switch (sessionQuery.data?.connectionType) {
    case 'PILOT':
      title = "Flight"
      Component = <PilotPage {...props} session={sessionQuery.data} />
      break;
    case 'FOLME':
      title = "Follow Me"
      Component = <PilotPage {...props} session={sessionQuery.data} />
      break;
    case 'ATC':
      title = "Station"
      Component = <AtcPage {...props} session={sessionQuery.data} />
      break;
    default:
      return <Redirect to={"/"} />
  }

  return <>
    <Slider title={`${title} ${sessionQuery.data?.callsign ?? ""}`} subtitle={fullName(sessionQuery.data?.user)} backgroundImage={SliderPicture} />
    {Component}
  </>
};
