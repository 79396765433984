import React, {
  EventHandler,
  KeyboardEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  FieldClearButton,
  InputLabel,
} from "@ivaoaero/front-components";
import "./searchbarsection.scss";
import moment from "moment";
import Select from "react-select";

export type SearchStateObject = {
  userId?: number;
  callsign?: string;
  role?: string;
  date?: string;
  from?: string;
  to?: string;
};

type SearchDisabledObject = {
  userId?: boolean;
  callsign?: boolean;
  role?: boolean;
  date?: boolean;
};

type SearchBarSectionType = {
  onSubmit: (filters: SearchStateObject) => void;
  defaultValues?: SearchStateObject;
  disabled?: SearchDisabledObject;
};

const roles = [
  { value: undefined, label: "All" },
  { value: "PILOT", label: "Pilot" },
  { value: "ATC", label: "ATC" },
  { value: "OBS", label: "Observer" },
  { value: "FOLME", label: "Follow Me" },
];

const roleMatching = {
  PILOT: { value: "PILOT", label: "Pilot" },
  ATC: { value: "ATC", label: "ATC" },
  OBS: { value: "OBS", label: "Observer" },
  FOLME: { value: "FOLME", label: "Follow Me" },
};

const selectCustomStyles = {
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: "black",
  }),
  option: (provided: any, state: any) => {
    let background = undefined;
    if (state.isSelected) background = "#3C55AC";
    else if (state.isFocused) background = "#3C55AC";

    return {
      ...provided,
      color: "black",
      background: background,
    };
  },
};

export const SearchBarSection: React.FC<SearchBarSectionType> = ({
  onSubmit,
  defaultValues = {},
  disabled = {},
}) => {
  const format: string = "D/M/YY";
  const [searchData, setSearchData] = useState<SearchStateObject>({});
  const datePickerRef = useRef();

  useEffect(() => {
    const newData = searchData;
    Object.entries(defaultValues).forEach(([key, value]) => {
      // @ts-ignore
      newData[key] = value;
    });

    setSearchData(newData);
  }, [defaultValues]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLElement>) => {
    // @ts-ignore
    const { id, value } = event.target;
    const extractIdRegex = /form-([a-zA-Z]+)/;
    if (extractIdRegex.test(id)) {
      setSearchData((data: SearchStateObject) => {
        // @ts-ignore
        return { ...data, [id.match(extractIdRegex)[1]]: value };
      });
    }
  };

  const handleSearchChangeSelect = (id: string) => (a: any) => {
    setSearchData({ ...searchData, [id]: a.value });
  };

  const handleDateTimePickerChange = (_: SyntheticEvent, picker: any) => {
    if (picker.startDate) {
      picker.element.val(
        picker.startDate.format(format) + " - " + picker.endDate.format(format)
      );
    } else {
      picker.element.val("");
    }

    setSearchData((data) => {
      return { ...data, date: picker.element.val() };
    });
  };

  const handleFieldClear = (id: string, event: SyntheticEvent) => {
    event.preventDefault();
    setSearchData({ ...searchData, [id]: "" });
  };

  const handleDatePickerClear = (id: string, event: SyntheticEvent) => {
    event.preventDefault();
    // @ts-ignore
    datePickerRef.current.ref.value = "";
    setSearchData({ ...searchData, [id]: "" });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") handleSubmit(event);
  };

  const handleSubmit: EventHandler<SyntheticEvent> = (
    event: SyntheticEvent
  ) => {
    if (event) event.preventDefault();
    const actualData: SearchStateObject = {};

    Object.entries(searchData).forEach(([key, value]) => {
      if (!value) return;

      // @ts-ignore
      actualData[key] = value;
    });

    if (actualData["date"]) {
      const [from, to] = actualData["date"].replace(" ", "").split("-");
      actualData["from"] = moment(from, format).format("YYYY-MM-DDT00:00:00");
      actualData["to"] = moment(to, format).format("YYYY-MM-DDT23:59:59");
    } else {
      delete actualData["from"];
      delete actualData["to"];
    }
    delete actualData["date"];
    onSubmit(actualData);
  };

  return (
    <form>
      <div className="row bg-white justify-content-md-between p-4 main_search_bar">
        <div className="col-12 col-md-6 col-lg-3 main_search_bar_input">
          <InputLabel id="userId" value="VID" />
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              id="form-userId"
              value={searchData.userId}
              onChange={handleSearchChange}
              min={0}
              max={999999}
              disabled={disabled.userId}
              onKeyDown={handleKeyDown}
            />
            <FieldClearButton
              id="userId"
              handleFieldClear={handleFieldClear}
              disabled={disabled.userId}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-3 main_search_bar_input">
          <InputLabel id="callsign" value="Callsign" />
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="form-callsign"
              value={searchData.callsign}
              onChange={handleSearchChange}
              disabled={disabled.callsign}
              onKeyDown={handleKeyDown}
            />
            <FieldClearButton
              id="callsign"
              handleFieldClear={handleFieldClear}
              disabled={disabled.callsign}
            />
          </div>
        </div>
        <div className="col-12 col-md-5 col-lg-3 pt-3 pt-lg-0 main_search_bar_input">
          <InputLabel id="date" value="Date Range" />
          <div className="input-group">
            <DateRangePicker
              // @ts-ignore
              ref={datePickerRef}
              initialSettings={{
                autoApply: true,
                autoUpdateInput: false,
              }}
              onApply={handleDateTimePickerChange}
              onCancel={handleDateTimePickerChange}
            >
              <input
                type="text"
                className="form-control"
                id="date"
                autoComplete="false"
                defaultValue={searchData.date}
                disabled={disabled.date}
              />
            </DateRangePicker>
            <FieldClearButton
              id="date"
              handleFieldClear={handleDatePickerClear}
              disabled={disabled.callsign}
            />
          </div>
        </div>
        <div className="col-12 col-md-2 col-lg-2 d-flex align-items-end pt-3 pt-lg-0">
          <button
            className={`btn btn-block text-white font-weight-bold main_search_bar_button`}
            type="button"
            onClick={handleSubmit}
          >
            Search
          </button>
        </div>
      </div>
    </form>
  );
};
