import { useQuery } from "react-query";
import { FlightTrackPointDTO } from "../../../common/dto/flight-track-point.dto";
import { useSettings } from "../../../settings";
import Http from "../../../common/utils/Http";

const fetchTracks = (sessionId: number) => {
  return Http<FlightTrackPointDTO[]>({ url: `/v2/tracker/sessions/${sessionId}/tracks` })
};

export const useTracks = (sessionId: number) => {
  const settings = useSettings();
  const query = useQuery(['session', sessionId, 'tracks'], () => fetchTracks(sessionId))

  const tracks = (query.data ?? []).filter((_, index) => {
    return index % (settings.data?.tracks_interval ?? 1) === 0
  })

  return { ...query, data: tracks }
}
