import { useQuery } from "react-query";
import Http from "../../../common/utils/Http";

export const usePositionInformation = (callsign: string) => {
  callsign = callsign.replace("_OBS", "");
  const callsignSplit = callsign.split("_");
  const positionStr = callsignSplit[callsignSplit.length - 1];

  const getATCPosition = (callsign: string) => {
    return Http({ url: `/v2/ATCPositions/${callsign}` })
  };

  const getSubcenters = (callsign: string) => {
    return Http({ url: `/v2/subcenters/${callsign}` })
  };

  return useQuery(['position', callsign], () => {
    switch (positionStr) {
      case "DEL":
      case "GND":
      case "TWR":
      case "DEP":
      case "APP":
        return getATCPosition(callsign);
      case "CTR":
      case "FSS":
        return getSubcenters(callsign);
    }
  })
}
