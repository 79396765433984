import { useQuery } from "react-query"
import { SessionDTO } from "../../common/dto/session.dto"
import { SessionsFilter } from "../../sessions"
import Http from "../../common/utils/Http"

const fetchSessions = async (params: SessionsFilter & { page: number, perPage: number }) => {
  return Http<{ items: SessionDTO[] }>({ url: '/v2/tracker/sessions', params }).then(data => data.items)
}

export const useHomeSessions = (connectionType: string, filters: Omit<SessionsFilter, "connectionType">) => {
  const sessions = useQuery(['home_sessions', connectionType, filters], () => {
    return fetchSessions({ ...filters, connectionType, page: 1, perPage: 5 })
  }, {
    enabled: Boolean(filters.userId || filters.callsign)
  })

  return sessions;
}